import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useAddUserMutation } from '../../Redux/services/user';
import { decryptRole, encryptPassword } from '../../utils/cryptoUtils';
import { regexPatterns, userRoleConstants, userStatusConstants } from '../../utils/constant';

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function AddUserModal({ show, onHide, masterData = {} }) {
  const [addUser, { isLoading }] = useAddUserMutation();
  const [decryptedRole, setDecryptedRole] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNo: '',
      role: masterData?.data?.roles ? {
        label: masterData?.data?.roles?.find((role) => role?.id === userRoleConstants.ADMIN)?.name,
        value: masterData?.data?.roles?.find((role) => role?.id === userRoleConstants.ADMIN)?.id,
      } : '',
      status: masterData?.data?.userStatuses ? {
        label: masterData?.data?.userStatuses
          ?.find((userStatus) => userStatus?.id === userStatusConstants.ACTIVE).name,
        value: masterData?.data?.userStatuses
          ?.find((userStatus) => userStatus?.id === userStatusConstants.ACTIVE).id,
      } : '',
      password: '',
    },
  });

  useEffect(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    setDecryptedRole(roleDecrypted);

  }, []);

  useEffect(() => {
    if (decryptedRole?.id === userRoleConstants.ADMIN) {
      setValue('role', {
        label: 'User',
        value: userRoleConstants.USER,
      });
    }
  }, [decryptedRole, masterData, setValue]);

  const onSubmit = async (data) => {
    const button = document.getElementById('save-button');
    button.disabled = true;
    const encryptedPassword = encryptPassword(data.password);
    const reqBody = {
      ...data,
      password: encryptedPassword,
      role: data.role.value,
      status: data.status.value,
    };
    const response = await addUser(reqBody);
    button.disabled = false;
    if (response?.data?.success) {
      window.toastr.success('User saved successfully!');
      onHide();
    }
    if (response.error) {
      window.toastr.error(response?.error?.data?.error?.[0]?.message ?? response.error.message);
    }
  };

  const renderRoles = () => masterData?.data?.roles?.map((userRole) => ({
    label: userRole?.name,
    value: userRole?.id,
  }));

  const renderStatus = () => masterData?.data?.userStatuses?.map((userStatus) => ({
    label: userStatus?.name,
    value: userStatus?.id,
  }));
  return (
    <Modal show={show} onHide={() => onHide()} centered>
      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Add User</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">

        <form id="kt_modal_new_card_form" className="form" onSubmit={handleSubmit(onSubmit)}>

          <div className="row mt-5">

            <div className="col-md-6 fv-row">

              <label htmlFor="name" className="required fs-6 fw-semibold form-label mb-2 assign-to">Full
                Name
              </label>
              <input
                {...register('name', {
                  required: { value: true, message: 'Name is required' },
                  validate: (value) => {
                    if (value.trim() === '') {
                      return 'Required';
                    }
                    if (value.trim().length < 1) {
                      return "Input can't be less than 1 characters";
                    }
                    if (value.trim().length > 50) {
                      return "Input can't be more then 50 characters";
                    }
                    return true;
                  },
                })}
                type="text"
                className="form-control"
              />
              {errors?.name
                 && <div className="text-danger small"> {errors.name?.message} </div>}
            </div>
            <div className="col-md-6 fv-row">

              <label htmlFor="email" className="required fs-6 fw-semibold form-label mb-2 assign-to">Email</label>
              <input {...register('email', { required: { value: true, message: 'Email is required' }, pattern: { value: regexPatterns.USER_EMAIL, message: 'Please insert a valid email address' } })} id="email" type="text" className="form-control" />
              {errors?.email
                 && <div className="text-danger small"> {errors.email?.message} </div>}
            </div>
            <div className="col-md-6 fv-row mt-5">
              <label htmlFor="phoneNo" className="fs-6 fw-semibold form-label mb-2 assign-to">Phone No.</label>
              <input
                {...register('phoneNo', {
                  pattern: { value: regexPatterns.USER_PHONENUMBER, message: 'Invalid Phone no' },
                })}
                maxLength={10}
                type="tel"
                className="form-control"
              />
              {errors?.phoneNo
                 && <div className="text-danger small"> {errors.phoneNo?.message} </div>}
            </div>
            <div className="col-md-6 fv-row mt-5">

              <label htmlFor="role" className="required fs-6 fw-semibold form-label mb-2 assign-to">Role</label>
              <Controller
                name="role"
                rules={{
                  required: { value: true, message: 'Role is required' },
                }}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={renderRoles()}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Role"
                    styles={customSelectStyles}
                  />
                )}
              />
              {errors?.role
                 && <div className="text-danger small"> {errors.role?.message} </div>}
            </div>

            <div className="col-md-6 mt-5 fv-row">

              <label htmlFor="status" className="required fs-6 fw-semibold form-label mb-2 assign-to">Status</label>
              <Controller
                name="status"
                rules={{
                  required: { value: true, message: 'Status is required' },
                }}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    components={{ IndicatorSeparator: () => null }}
                    options={renderStatus()}
                    placeholder="Status"
                    styles={customSelectStyles}
                  />
                )}
              />
              {errors?.status
                 && <div className="text-danger small"> {errors.status?.message} </div>}
            </div>

            <div className="col-md-6 mt-5 fv-row ">

              <label htmlFor="password" className="required fs-6 fw-semibold form-label mb-2 assign-to">Set Password</label>
              <input
                {...register('password', {
                  required: { value: true, message: 'Password is required' },
                  maxLength: { value: 20, message: 'Maximum length should be 20 characters' },
                  minLength: { value: 6, message: 'Minimum length should be 6 characters' },
                  pattern: { value: regexPatterns.USER_PASSWORD, message: 'Password must atleast have 1 alphabet and 1 number' },
                })}
                className="form-control rounded"
                autoComplete="off"
              />
              {errors?.password
                 && <div className="text-danger small"> {errors.password?.message} </div>}
            </div>
          </div>

          <div className="text-left pt-5 mt-5">

            <button
              type="submit"
              id="save-button"
              className="btn btn-sm btn-secondary"
            >
              {!isLoading ? <span className="indicator-label">Save</span>
                : (
                  <span>
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
            </button>
            <button
              onClick={() => onHide({ reOpen: false })}
              type="button"
              id="cancel-button"
              className="btn btn-sm btn-light ms-3"
            >Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserModal;
