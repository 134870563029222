import { configureStore } from '@reduxjs/toolkit';
import { authService } from './services/auth';
import { authSlice } from './slices/auth';
import { userService } from './services/user';
import { userSlice } from './slices/user';
import { masterDataService } from './services/masterData';
import { taskService } from './services/task';
import { kanbanService } from './services/kanban';
import { homeService } from './services/home';
import { projectsApi } from './services/project';
import { globalSearchService } from './services/globalSearch';
import { notificationService } from './services/notifications';

const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [userService.reducerPath]: userService.reducer,
    [masterDataService.reducerPath]: masterDataService.reducer,
    [taskService.reducerPath]: taskService.reducer,
    [kanbanService.reducerPath]: kanbanService.reducer,
    [homeService.reducerPath]: homeService.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [globalSearchService.reducerPath]: globalSearchService.reducer,
    [notificationService.reducerPath]: notificationService.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(
      authService.middleware,
      masterDataService.middleware,
      userService.middleware,
      taskService.middleware,
      kanbanService.middleware,
      homeService.middleware,
      projectsApi.middleware,
      globalSearchService.middleware,
      notificationService.middleware,
    ),
});
export default store;
