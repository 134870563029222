import {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProjectCard from './card';
import AdminCarousel from './carousel';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import { useGetProjectsForOwnerMutation } from '../../Redux/services/home';
import { decryptRole } from '../../utils/cryptoUtils';
import { userRoleConstants } from '../../utils/constant';
import WeeklyTasks from './weeklyTasks';
import { useGetLoggedUserQuery } from '../../Redux/services/user';

export default function Home() {
  const { data: masterData } = useGetMasterDataQuery();
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [fetchProjects, { data: projectsData, isLoading }] = useGetProjectsForOwnerMutation();
  const { data: loggedUser } = useGetLoggedUserQuery();

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const owlCarouselRef = useRef(null);

  const items = useMemo(() => {
    if (Array.isArray(masterData?.data?.owners)) {
      return masterData.data.owners.map((owner) => ({
        id: owner.id,
        label: `${owner.firstName.charAt(0)}${owner.lastName.charAt(0)}`,
        name: `${owner.firstName} ${owner.lastName}`,
      }));
    }
    return [];
  }, [masterData]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (masterData?.data?.projects.length > 0) {
      fetchProjects({ size: masterData?.data?.projects?.length, owner: selectedOwner });

    }
    // eslint-disable-next-line
  }, [masterData?.data?.projects?.length, selectedOwner]);

  useEffect(() => {
    if (masterData?.data?.owners?.length > 0
      && !selectedOwner
      && roleMemo?.id === userRoleConstants.SUPER_ADMIN) {
      const firstOwner = masterData.data.owners[0];
      setSelectedOwner(firstOwner.id);
      setActiveIndex(0);
      localStorage.setItem('selectedOwner', firstOwner.id);
      localStorage.setItem('activeIndex', 0);
    }
    // eslint-disable-next-line
  }, [masterData, selectedOwner,]);

  useEffect(() => {
    if (loggedUser?.data.userId && roleMemo?.id === userRoleConstants.ADMIN) {
      setSelectedOwner(loggedUser?.data?.userId);
    }

  }, [loggedUser?.data?.userId, roleMemo]);
  const handleOwnerClick = (ownerId, index) => {
    setSelectedOwner(ownerId);
    setActiveIndex(index);
    localStorage.setItem('selectedOwner', ownerId);
    localStorage.setItem('activeIndex', index);
  };

  useEffect(() => {
    if (owlCarouselRef.current && activeIndex !== null) {
      owlCarouselRef.current.to(activeIndex, 0);
    }
  }, [activeIndex]);

  return (
    <>
      {isLoading || showLoader ? (
        <div id="flexbox">
          <div className="nb-spinner" />
        </div>
      ) : null}
      <div className={`d-flex flex-column mt-5 flex-root ${showLoader ? 'blur-bg' : ''}`}>
        <Row className="gy-5 g-xl-10 mt-0">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-0 mt-0">
            <div className="fs-2 fw-bold">
              <h2 className="mb-0">Home</h2>
              <Breadcrumb className="fw-semibold fs-base mb-1">
                <Breadcrumb.Item className="text-muted">
                  <Link to="/" className="text-muted text-hover-primary">
                    Home
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {(roleMemo?.id !== userRoleConstants.USER)
            && (
              <>
                {roleMemo?.id !== userRoleConstants.ADMIN && (
                  <Row className="mt-5 pt-0">
                    <Col xl={12} lg={12} md={12}>
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                        <div className="fs-2 fw-bold">
                          <h2 className="mb-3">Admins</h2>
                        </div>
                      </div>
                      <Row>
                        <AdminCarousel
                          key={activeIndex}
                          items={items}
                          activeIndex={activeIndex}
                          onOwnerClick={handleOwnerClick}
                          owlCarouselRef={owlCarouselRef}
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                {((((projectsData?.data?.projects?.length) === 0 || !projectsData?.data)
                  && (roleMemo?.id !== userRoleConstants.USER)))
                  && (
                    <div className="row mt-5 pt-0 px-5">
                      <h2 className="mb-3">Projects</h2>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="row mt-5 pt-0">
                          <div className="col-xl-12 col-lg-12 col-md-12">

                            <div className="row gy-5 g-xl-10">

                              <div className="col-xl-12">
                                <div className="card card-flush mb-6 mb-xl-9 chart_card h-100">
                                  <div className="card-body pt-9 pb-5">
                                    <div className="row justify-content-center text-center">
                                      <div className="col-lg-10">
                                        <img src="../assets/media/warning.png" alt="" className="pt-1" />
                                        <p className="lead mt-4 text-600 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">You have no Project.</p>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )}
                {projectsData?.data?.projects?.length > 0 && (
                  <Row className="mt-5 pt-5">
                    <Col xl={12} lg={12} md={12}>
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                        <div className="fs-2 fw-bold">
                          <h2 className="mb-3">Projects</h2>
                        </div>
                      </div>
                      <Row className="gy-5 g-xl-10">
                        <ProjectCard
                          projectsData={projectsData?.data}
                          masterData={masterData?.data}
                          roleMemo={roleMemo}
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
        </Row>
        {roleMemo?.id === userRoleConstants.USER && <WeeklyTasks />}
      </div>
    </>
  );
}
