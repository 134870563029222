import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function AdminCarousel({
  items, activeIndex, onOwnerClick, owlCarouselRef,
}) {

  if (items.length === 0) {
    return <div>There are no admins to show!</div>;
  }

  const carouselOptions = {
    dots: false,
    loop: false,
    items: 6,
    margin: 10,
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      1024: { items: 3 },
      1200: { items: 6 },
      1400: { items: 6 },
      1600: { items: 6 },
    },
  };

  return (
    <OwlCarousel ref={owlCarouselRef} className="owl-theme" {...carouselOptions}>
      {items.map((item, index) => (
        <div
          key={item.id}
          role="button"
          tabIndex={0}
          className={`item card py-3 ${activeIndex === index ? 'active bg-light-info' : ''}`}
          onClick={() => onOwnerClick(item.id, index)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onOwnerClick(item.id, index);
            }
          }}
        >
          <div className="p-3 py-3">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-circle symbol-35px overflow-hidden me-3">
                <div className="symbol-label solid fs-3 bg-light-success text-success">
                  <span className="fs-3 text-info fw-normal"> {item.label} </span>
                </div>
              </div>
              <div className="flex-fill ms-3 text-truncate fs-4">
                <div className="small">{item.name}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
}
